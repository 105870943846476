<template>
  <div>
    <!-- 单次选择的组件 -->
    <van-field
      v-model="propsObject.result"
      is-link
      :required="propsObject.required"
      readonly
      name="picker"
      :label="propsObject.label"
      :placeholder="propsObject.placeholder"
      @click="reactiveParams.showPicker = true"
    />
    <!-- 单次选择的弹出框 -->
    <van-popup v-model:show="reactiveParams.showPicker" position="bottom">
      <van-picker
        :columns="propsObject.columns"
        @confirm="onConfirm"
        @cancel="reactiveParams.showPicker = false"
        :columns-field-names="propsObject.customFieldName"
      />
    </van-popup>
  </div>
</template>
<script>
  import { ref, inject, reactive, toRefs } from "vue";
  export default {
    emits: ["onConfirm"],
    props: {
      result: String,
      columns: Array,
      placeholder: String,
      customFieldName: Object,
      label: String,
      required: Boolean,
    },
    setup(props, context) {
      const reactiveParams = reactive({
        result: "",
        label: "",
        placeholder: "",
        showPicker: false,
        columns: [],
        customFieldName: { text: "nametext" },
      });
      let propsObject = props;
      function onConfirm(e) {
        context.emit("onConfirm", e);
        reactiveParams.showPicker = false;
      }
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        propsObject,
        onConfirm,
      };
    },
  };
</script>
<style scoped></style>
