<template>
  <div>
    <!-- 个人信息 -->
    <div class="styleInfo">
      <div class="flx">
        <div>科队：</div>
        <div>{{ reactiveParams.itemList.nametext }}</div>
      </div>
      <div class="flx">
        <div>班组：</div>
        <div>{{ reactiveParams.itemList.groupName }}</div>
      </div>
      <div class="flx">
        <div>工作日期：</div>
        <div>{{ reactiveParams.itemList.workdate }}</div>
      </div>
      <div class="flx">
        <div>班次：</div>
        <div>{{ reactiveParams.itemList.flight }}</div>
      </div>
      <div class="flx">
        <div>班组净收入：</div>
        <div>{{ reactiveParams.itemList.netincome }}</div>
      </div>
    </div>
    <!-- 工作量左 -->
    <div style="width: 100%; height: 0.3rem"></div>
    <div
      class="styleInfo"
      v-for="(item, index) in reactiveParams.dataList"
      :key="index"
    >
      <div class="flx top">
        <div>班组</div>
        <div>{{ item.groupName }}</div>
      </div>
      <div class="flx top">
        <div>姓名</div>
        <div>{{ item.customerName }}</div>
      </div>
      <div class="flx top">
        <div>岗位</div>
        <div>{{ item.station }}</div>
      </div>
      <div class="flx top">
        <div>考勤</div>
        <div>{{ item.conditionName }}</div>
      </div>
      <div class="flx top">
        <div>方式</div>
        <div>{{ item.modeName }}</div>
      </div>
      <div class="flx top">
        <div>系数</div>
        <div>{{ item.stationratio }}</div>
      </div>
      <div class="flx top">
        <div>基础分</div>
        <div>{{ item.income }}</div>
      </div>
      <div class="flx top">
        <div>分值</div>
        <div>{{ item.score }}</div>
      </div>
      <div class="flx top">
        <div>备注</div>
        <div>{{ item.summary }}</div>
      </div>
      <div class="flx">
        <van-button type="primary" size="small" @click="compile(item)">
          编辑
        </van-button>
        <van-button type="danger" size="small" @click="deletesone(item)">
          删除
        </van-button>
        <van-button type="success" size="small" @click="log(item)">
          日志
        </van-button>
      </div>
    </div>
    <!-- 基分添加 -->
    <div>
      <Add @addRoute="addRoute" />
    </div>
    <!-- 添加弹框 -->
    <van-popup
      v-model:show="MaterialShow"
      :style="{ height: '60%', width: '70%', 'border-radius': '0.2rem' }"
      closeable
    >
      <div style="padding: 0.2rem">
        <div
          v-for="(item, index) in reactiveParams.logArrays"
          :key="index"
          style="margin-top: 0.4rem"
        >
          {{ item }}
          <van-divider />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
    onMounted,
  } from "vue";
  import Add from "@/components/componentAdd/index";
  import { useRoute, useRouter } from "vue-router";
  import SingleSelection from "@/components/AllScreening/SingleSelection";
  import { Toast } from "vant";
  export default {
    components: {
      SingleSelection,
      Add,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const reactiveParams = reactive({
        propstaus: false,
        time: "",
        result: "",
        showPicker: false,
        columns: [],
        MaterialShow: false,
        itemList: "",
        status: true,
        materialsLists: [],
        columnsFieldNames: { text: "name" },
        dataList: [],
        // 添加班级选择
        team: {
          result: "",
          columnsTeam: [],
          label: "班组",
          customFieldName: { text: "name" },
          required: true,
        },
        logArrays: [],
      });

      // 路由初始化
      const route = useRoute();
      const router = useRouter();

      // 获取params数据信息
      reactiveParams.itemList = JSON.parse(route.query.item);
      console.log(reactiveParams.itemList, "获取数据信息");

      // 初始化方法
      const list = async () => {
        // 获取科队信息
        let listArray = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getLaCompanysList
        );

        // 数据filter循环(信息处理)
        let array = listArray.data.data.filter((item) => {
          return item.id == reactiveParams.itemList.companyId;
        });
        reactiveParams.itemList.nametext = array[0].name;

        // 获取基分列表
        let listPurchase = await proxy.$Api.get(
          proxy.$laDaysGroupsApi.laDaysCustomersList,
          {
            order: "sort.desc",
            companyId: "",
            keyword: "",
            keywords: "groupName,customerName",
            daysgroupId: reactiveParams.itemList.id,
          }
        );
        console.log(listPurchase, "信息方法");
        reactiveParams.dataList = listPurchase.data.data;
        let arrays = [];
        reactiveParams.dataList.forEach((item) => {
          item.scoreinfo.split("|").forEach((item) => {
            arrays.push(item);
          });
        });
        reactiveParams.logArrays = arrays;
      };
      // 初始化信息
      onMounted(() => {
        list();
      });

      // 基分编辑
      function compile() {
        router.push({
          name: "daysgroupsBasicComponentAdd",
          query: { id: reactiveParams.itemList.companyId, status: 1 },
        });
      }

      // 删除
      const deletesone = async (item) => {
        let deletesArray = await proxy.$Api.delete(
          `${proxy.$laDaysGroupsApi.laDaysCustomersList}?id=${item.id}`
        );
        if (deletesArray.data.code == 200) {
          Toast("删除成功！");
          list();
        } else {
          Toast("操作失败！");
        }
      };

      // 添加
      const addRoute = async () => {
        router.push({
          name: "daysgroupsBasicComponentAdd",
          query: { id: reactiveParams.itemList.companyId },
        });
      };
      // 日志
      const log = async (item) => {
        reactiveParams.MaterialShow = true;
      };

      // 班组选择确认
      function onConfirmbz() {}
      // 返回
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        addRoute,
        deletesone,
        onConfirmbz,
        compile,
        log,
      };
    },
  };
</script>
<style scoped>
  .styleHeader {
    font-size: 0.5rem;
    font-weight: 600;
    text-align: center;
  }
  .styleInfo {
    width: 90%;
    margin: 0 auto;
    border-radius: 0.2rem;
    background-color: #fff;
    margin-top: 0.2rem;
    padding: 0.1rem;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 0.3rem;
    color: #333;
  }
  .top {
    margin-top: 0.2rem;
  }
</style>
